<template>
  <div v-if="addEditDialogFlag" class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <div class="content_box">
          <div class="box_header">
            <div class="title">
              {{ type == "jnhjqk" ? "近年获奖情况" : "培育孵化" }}信息填写
            </div>
            <div @click="closeAddEditDialog" class="close_btn"></div>
          </div>
          <div class="box_conter">
            <el-form
              :model="dialogData"
              :rules="rules"
              ref="ruleForm"
              label-width="6.25vw"
              class="demo-ruleForm"
            >
              <el-form-item :label="formLabel[0]" prop="dataDetailed">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  v-model="dialogData.dataDetailed"
                ></el-input>
              </el-form-item>
              <el-form-item :label="formLabel[1]" prop="dataField1">
                <el-input
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  v-model="dialogData.dataField1"
                ></el-input>
              </el-form-item>
              <el-form-item :label="formLabel[2]" prop="dataField2">
                <el-select
                  v-if="type == 'jnhjqk'"
                  v-model="dialogData.dataField2"
                  placeholder="请选择类型"
                >
                  <el-option
                    v-for="(item, index) in typeArr"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-date-picker
                  v-if="type == 'pyfh'"
                  v-model="dialogData.dataField2"
                  type="month"
                  placeholder="选择日期"
                  format="yyyy 年 MM 月"
                  value-format="yyyy年MM月"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input-number
                  onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                  v-model="dialogData.sort"
                  :min="0"
                  :precision="0"
                ></el-input-number>
              </el-form-item>
            </el-form>
          </div>
          <div class="box_footer">
            <div class="btn_box">
              <div @click="closeAddEditDialog" class="close_btn">取消</div>
              <div
                :loading="submitBtnLoading"
                @click="submitBtn"
                class="submit_btn"
              >
                {{ this.dialogData.dataId ? "确定修改" : "确定新增" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postDataDetail, putDataDetail } from "@/api/dtdata";
export default {
  props: {
    addEditDialogFlag: Boolean,
    dialogData: Object,
    type: String,
  },
  components: {},
  data() {
    return {
      dataField2: "",
      formLabel: [],
      submitBtnLoading: false,
      typeArr: [
        { label: "国家级", value: "国家级" },
        { label: "省级", value: "省级" },
        { label: "市级", value: "市级" },
      ],
      rules: {
        dataDetailed: [
          {
            required: true,
            message: `请输入${this.type == "jnhjqk" ? "奖项" : "项目名称"}`,
            trigger: "blur",
          },
        ],
        dataField1: [
          {
            required: true,
            message: `请输入${this.type == "jnhjqk" ? "颁发单位" : "责任人"}`,
            trigger: "blur",
          },
        ],
        dataField2: [
          {
            required: true,
            message: `${
              this.type == "jnhjqk" ? "请选择获奖级别" : "请选择计划完成时间"
            }`,
            trigger: "change",
          },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
    };
  },
  watch: {
    addEditDialogFlag: {
      handler(newData, oldData) {
        if (newData) {
          console.log(this.dialogData.sort);
          this.dialogData.sort = this.dialogData.sort
            ? this.dialogData.sort
            : 1;
        }
        // newData.sort = newData.sort ? newData.sort : "1";
        // console.log("dialogData", newData);
      },
      immediate: true, // 是否进行第一次监测
      deep: true, // 深度监测,监测引用数据类型是必须深度监测
    },
  },
  created() {
    console.log(this.type);
    switch (this.type) {
      case "jnhjqk":
        this.formLabel = ["奖项", "颁发单位", "获奖级别"];
        this.dialogData.dataName = "hjqk-statistics-jnhjqk";
        break;
      case "pyfh":
        this.formLabel = ["项目名称", "责任人", "计划完成时间"];
        this.dialogData.dataName = "hjqk-statistics-pyfh";
        break;
    }
    console.log(this.dialogData);
  },
  mounted() {},
  methods: {
    closeAddEditDialog() {
      this.dataField2 = "";
      this.$refs.ruleForm.resetFields();
      this.$emit("closeAddEditDialog", false);
    },
    submitBtn() {
      if (this.dataField2) {
        this.dialogData.dataField2 = this.dataField2;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.dialogData.dataModule = "hjqk";
          this.dialogData.dataTyp = "hjqk-statistics";
          switch (this.type) {
            case "jnhjqk":
              this.dialogData.dataName = "hjqk-statistics-jnhjqk";
              break;
            case "pyfh":
              this.dialogData.dataName = "hjqk-statistics-pyfh";
              break;
          }
          this.submitBtnLoading = true;
          let requestName = this.dialogData.dataId
            ? putDataDetail
            : postDataDetail;
          console.log("this.dialogData", this.dialogData);
          requestName(this.dialogData)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(
                  `${this.dialogData.dataId ? "修改成功" : "添加成功"}`
                );
                this.submitBtnLoading = false;
                this.closeAddEditDialog();
                this.$emit("getList");
              }
            })
            .catch((err) => {
              this.$message.info(res.msg);
              this.submitBtnLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_cover_cloth {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  .dialog_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 5vh;
    .box_content {
      background: transparent;
      width: 48vw;
      .bg_img {
        width: 48vw;
        img {
          width: 100%;
        }
      }
      .content_box {
        color: #fff;
        width: 48vw;
        line-height: 2vw;
        background: url("../../../assets/image/dialogBg.png") no-repeat;
        background-size: 100% 100%;
        // min-height: 55vh;
        .box_header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          margin-bottom: 1vw;
          .title {
            color: #fff;
            font-weight: bold;
            font-size: 0.9vw;
            background: #cd80a2;
            border-bottom-left-radius: 0.5vw;
            border-bottom-right-radius: 0.5vw;
            border: 0.1vw solid;
            padding: 0 2vw;
          }
          .close_btn {
            background: url("../../../assets/image/zhfe/dialogClose.png")
              no-repeat;
            background-size: 100% 100%;
            position: absolute;
            width: 2vw;
            height: 2vw;
            top: -1vw;
            right: -1vw;
            cursor: pointer;
            // opacity: 0;
            padding: 0.5vw;
          }
        }
        .box_conter {
          padding: 0 2vw;
          ::v-deep {
            .el-form-item {
              margin-bottom: 1.15vw;
            }
            .el-form-item__label {
              color: rgba(255, 212, 227, 1);
              font-size: 0.73vw;
              line-height: 2.1vw;
              padding: 0 0.62vw 0 0;
            }
            .el-textarea__inner,
            .el-input__inner {
              color: rgba(255, 212, 227, 1);
              background-color: transparent;
              &::placeholder {
                color: rgba(255, 212, 227, 1);
              }
            }
            .el-select .el-input .el-select__caret {
              color: rgba(255, 212, 227, 1);
            }
            .el-input__prefix,
            .el-input__suffix {
              color: rgba(255, 212, 227, 1);
            }
            .el-textarea {
              font-size: 0.75vw;
            }
            .el-form-item__content {
              line-height: 2vw;
              font-size: 0.75vw;
            }
            .el-input {
              font-size: 0.73vw;
            }
            .el-input--suffix .el-input__inner {
              padding-right: 1.6vw;
            }
            .el-input__inner {
              height: 2.2vw;
              line-height: 2.2vw;
              // padding: 0 0 0 1.5vw;
            }
            .el-select .el-input .el-select__caret {
              font-size: 0.73vw;
            }
            .el-input-number__decrease,
            .el-input-number__increase {
              background: transparent;
              color: rgb(255, 212, 227);
              width: 2.05vw;
              font-size: 0.7vw;
            }
            .el-input {
              font-size: 0.7vw;
            }
            .el-input-number {
              width: 11.3vw;
              line-height: 2vw;
            }
            .el-input__icon {
              line-height: 0;
            }
          }
        }
        .box_footer {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1vw;
          color: #ffd4e4;
          .btn_box {
            display: flex;
            div {
              border: 0.1vw solid #ffd4e4;
              margin-right: 1vw;
              padding: 0.1vw 4vw;
              cursor: pointer;
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }
}
</style>