<template>
  <div class="table_list">
    <div class="table_title">
      <div class="title_row">
        <div
          class="title_item"
          v-for="(item, index) in tableTitle"
          :key="index"
          :style="[
            {
              width: item.width,
            },
          ]"
        >
          <div class="item">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div v-if="tableData.length > 0" class="table_row">
      <div class="row_item" v-for="(item, index) in tableData" :key="index">
        <div :style="{ width: tableTitle[0].width }" class="item_solt">
          {{ item.sort }}
        </div>
        <div :style="{ width: tableTitle[1].width }" class="item_name">
          {{ item.dataDetailed }}
        </div>
        <div :style="{ width: tableTitle[2].width }" class="item_birthTime">
          {{ item.dataField1 }}
        </div>
        <div :style="{ width: tableTitle[3].width }" class="item_post">
          {{ item.dataField2 }}
        </div>
        <div :style="{ width: tableTitle[4].width }" class="btn_box">
          <span v-if="isEditFlag" @click="deleteBtn(item)" class="delete_btn"
            >删除</span
          >
          <span v-if="isEditFlag" @click="editBtn(item)" class="edit_btn"
            >编辑</span
          >
        </div>
      </div>
    </div>
    <div v-else class="no_data">暂无数据</div>
  </div>
</template>
<script>
export default {
  props: {
    tableData: Array,
    isEditFlag: Boolean,
  },
  components: {},
  data() {
    return {
      tableTitle: [
        { width: "3vw", name: "排序" },
        {
          width: "28vw",
          name: `${this.$route.query.type == "pyfh" ? "项目" : "奖项"}`,
        },
        {
          width: "28vw",
          name: `${this.$route.query.type == "pyfh" ? "责任人" : "颁发单位"}`,
        },
        {
          width: "28vw",
          name: `${
            this.$route.query.type == "pyfh" ? "计划完成时间" : "获奖级别"
          }`,
        },
        { width: "8vw", name: "操作" },
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    editBtn(item) {
      let obj = {
        data: item,
        type: "edit",
      };
      this.$emit("openDialog", obj);
    },
    deleteBtn(item) {
      let obj = {
        data: item,
        type: "delete",
      };
      this.$emit("openDialog", obj);
    },
  },
};
</script>
<style lang='scss' scoped>
.table_list {
  width: 102%;
  color: #fff;
  font-size: 1.5vh;
  .table_title {
    width: 100%;
    .title_row {
      display: flex;
      align-items: center;
      height: 5vh;
      background: #b44b7a;
      .title_item {
        text-align: center;
        width: 13vw;
        text-align: center;
        &:last-child {
          padding-right: 2vh;
        }
      }
    }
  }
  .table_row {
    height: 60vh;
    width: 100%;
    overflow-y: auto;
    .row_item {
      display: flex;
      background: rgba(180, 75, 122, 0.4);
      &:nth-child(2n + 1) {
        background: rgba(180, 75, 122, 0.2);
      }
      div {
        width: 12.2vw;
        min-height: 6vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 1vh;
        text-align: center;
        line-height: 1vw;
        &:last-child {
          // padding-right: 4vh;
        }
      }
      .btn_box {
        span {
          cursor: pointer;
        }
        .edit_btn {
          margin: 0 1vw;
          color: #379aff;
        }
        .delete_btn {
          padding-right: 1vw;
          border-right: 0.1vw solid #fff;
          color: #73ff87;
        }
      }
    }
  }
  .no_data {
    background: rgba(180, 75, 122, 0.4);
    min-height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1vh;
    text-align: center;
  }
}
</style>