<template>
  <div class="main">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/return.png"
            @click="goBack"
          />
          <!-- <img
            class="fh"
            src="@/assets/image/zhfe/icon/gb.png"
            v-show="gb"
            @click="close"
          /> -->
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="sx"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="page_title">
      <div class="title_text">{{ titleText }}</div>
    </div>
    <div class="content">
      <div class="conter_header">
        <div class="form_box">
          <el-form
            ref="ruleForm"
            :inline="true"
            :model="queryParam"
            class="demo-form-inline"
          >
            <el-form-item label="" prop="dataDetailed">
              <el-input
                onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false;"
                v-model="queryParam.dataDetailed"
                :placeholder="placeholder"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="type == 'jnhjqk'" label="" prop="type">
              <el-select
                v-model="queryParam.dataField2"
                placeholder="请选择获奖级别"
              >
                <el-option
                  v-for="(item, index) in typeArr"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="btn_box">
                <div class="query_btn" @click="onQuery">查询</div>
                <div class="reset_btn" @click="onReset">重置</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="isEditFlag" class="btn_box">
          <div @click="openDialog({ data: {}, type: 'add' })" class="">
            新增
          </div>
          <div @click="importBtn" class="">导入</div>
          <!-- <div @click="exportBtn" class="">导出</div> -->
        </div>
      </div>
      <div
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="table_box"
      >
        <tableList
          :isEditFlag="isEditFlag"
          @openDialog="openDialog"
          :tableData="tableData"
        ></tableList>
      </div>
      <div class="pagination_box">
        <el-pagination
          :page-size="queryParam.pageSize"
          :current-page="queryParam.pageNum"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="changeNum"
          @size-change="changeSize"
        >
        </el-pagination>
      </div>
    </div>
    <dialogAlert
      :dialogVisible="dialogVisible"
      :dialogData="dialogData"
      @closeDialog="delCloseDialog"
      @determine="determine"
      :dialogTitle="dialogTitle"
    >
      <span slot="txt">{{ delDataTxt }}</span>
    </dialogAlert>
    <addEditDialog
      :queryParam="queryParam"
      :dialogData="dialogData"
      :addEditDialogFlag="addEditDialogFlag"
      :type="type"
      @closeAddEditDialog="closeAddEditDialog"
      @getList="getList"
    ></addEditDialog>

    <importDialog
      :type="type"
      :importDialog="importDialog"
      @importClose="importClose"
      @importSuccess="importSuccess"
    ></importDialog>
  </div>
</template>
<script>
import { listData } from "@/api/dtdata";
import Date from "@/views/commonComponents/date.vue";
import tableList from "./components/tableList.vue";
import addEditDialog from "./components/addEditDialog.vue";
import importDialog from "./components/importDialog.vue";
import dialogAlert from "@/views/homeImg/components/dialogAlert.vue";
import { deleteDataDetail } from "@/api/dtdata.js";
import { resetPage } from "@/utils/index.js";
export default {
  components: { Date, tableList, dialogAlert, addEditDialog, importDialog },
  data() {
    return {
      type: "",
      titleText: "",
      placeholder: "",
      loading: false,
      isEditFlag: false,
      typeArr: [
        { label: "国家级", value: "国家级" },
        { label: "省级", value: "省级" },
        { label: "市级", value: "市级" },
      ],
      queryParam: {
        dataModule: "hjqk",
        dataTyp: "hjqk-statistics",
        dataName: "",
        pageNum: 1,
        pageSize: 10,
        dataDetailed: "",
      },
      total: 0,
      tableData: [],
      dialogVisible: false,
      delDataTxt: "",
      dialogData: {},
      importDialog: false,
      dialogTitle: "系统提示",
      addEditDialogFlag: false,
    };
  },
  watch: {},
  async created() {
    this.type = this.$route.query.type;
    if (this.$cookies.get("pass") && this.$cookies.get("pass") == "fulian456") {
      this.isEditFlag = true;
    }
    if (this.type == "jnhjqk") {
      this.titleText = "近年获奖情况";
      this.placeholder = "请输入奖项";
      // this.queryParam.dataField2 = "国家级";
    } else {
      this.titleText = "培育孵化";
      this.placeholder = "请输入项目";
    }
    await this.getList();
  },
  mounted() {},
  methods: {
    importBtn() {
      this.importDialog = true;
    },
    importSuccess() {
      this.importDialog = false;
      this.queryParam.pageNum = 1;
      this.getList();
    },
    importClose(val) {
      this.importDialog = val;
    },
    closeAddEditDialog(value) {
      this.addEditDialogFlag = value;
    },
    openDialog(obj) {
      switch (obj.type) {
        case "add":
          this.dialogData = {
            sort: "",
            dataDetailed: "",
            dataField1: "",
            dataField2: "",
          };
          this.addEditDialogFlag = true;
          break;
        case "edit":
          this.onEdit(obj.data);
          break;
        case "delete":
          this.onDelete(obj.data);
          break;
      }
    },
    onEdit(row) {
      this.dialogData = JSON.parse(JSON.stringify(row));
      this.addEditDialogFlag = true;
    },
    onDelete(row) {
      console.log(row);
      let dataDetailed = this.type == "pyfh" ? "项目" : "奖项";
      this.dialogData = row;
      this.delDataTxt = `是否确认删除${dataDetailed}为 "${row.dataDetailed}" 的数据项吗?`;
      this.dialogVisible = true;
    },
    determine() {
      deleteDataDetail(this.dialogData.dataId)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.dialogVisible = false;
            this.queryParam.pageNum = resetPage(
              this.queryParam.pageNum,
              this.queryParam.pageSize,
              this.total,
              1
            );
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.info(res.msg);
        });
    },
    delCloseDialog() {
      this.dialogVisible = false;
    },
    onQuery() {
      this.queryParam.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.queryParam = {
        dataModule: "hjqk",
        dataTyp: "hjqk-statistics",
        dataName: "",
        pageNum: 1,
        pageSize: 10,
        dataDetailed: "",
        dataField2: "",
      };
      this.getList();
    },
    async getList() {
      switch (this.$route.query.type) {
        case "jnhjqk":
          this.queryParam.dataName = "hjqk-statistics-jnhjqk";
          break;
        case "pyfh":
          this.queryParam.dataName = "hjqk-statistics-pyfh";
          break;
      }
      this.loading = true;
      let { rows, total } = await listData(this.queryParam);
      this.loading = false;
      this.total = total;
      this.tableData = rows;
    },
    changeSize(val) {
      this.queryParam.pageSize = val;
      this.queryParam.pageNum = 1;
      this.getList();
    },
    changeNum(val) {
      this.queryParam.pageNum = val;
      this.getList();
    },
    // 清除cookies
    close() {
      this.$cookies.remove("pass"); // return this
      this.$router.go(0); //刷新当前页面
    },
    sx() {
      this.$router.go(0); //刷新当前页面
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>